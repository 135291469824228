import PieChartColors from "./PieChartColors";
import { TransactionChart2, TransactionIndicator } from "./TransactionChart2";
import { Button } from "antd";
import { FC, useCallback } from "react";
import { SalesAdvisorViewType } from "../domain/SalesAdvisorData";
import PieChartBase from "./PieChart";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";

type Props = {
  tipo: SalesAdvisorViewType;
};

export const DashboardContent: FC<Props> = ({ tipo }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onFullNavigate = useCallback(() => {
    if (!location.pathname.includes("all")) {
      navigate(`${location.pathname}/all`);
    }
  }, [navigate, location]);

  const transactions: Array<TransactionIndicator> = (() => {
    switch (tipo) {
      case "distribuidores":
        return [
          {
            key: "monto_oc",
            color: "#ea6839",
            name: "Monto Orden de Compra",
            label: "O. Compra",
          },
          {
            key: "monto_facturado",
            color: "#f8aa19",
            name: "Monto Facturado",
            label: "Facturado",
          },
        ];
      case "ventas":
      case "proyectos":
      default:
        return [
          {
            key: "monto_facturado",
            color: "#f8aa19",
            name: "Monto Facturado",
            label: "Facturado",
          },
          {
            key: "monto_cotizado",
            color: "rgba(26, 42, 83, 1)",
            name: "Monto Cotizado",
            label: "Cotizado",
          },
        ];
    }
  })();
  const title = (() => {
    switch (tipo) {
      case "distribuidores":
        return "Resumen valor de venta de distribuidores";
      case "ventas":
        return "Resumen de valor de venta general";
      case "proyectos":
      default:
        return "Resumen de valor de venta de proyectos";
    }
  })();

  const maxValue = (() => {
    switch (tipo) {
      case "distribuidores":
        return 30000;
      case "ventas":
        return 172000;
      case "proyectos":
      default:
        return 150000;
    }
  })();

  return (
    <div className="lg:grid grid-cols-1 lg:grid-cols-4 w-full h-full">
      <div className="px-2 py-2 w-full h-full col-span-1 lg:col-span-3">
        <div className="lg:h-full flex flex-col px-4 w-full bg-white rounded-xl lg:overflow-hidden shadow gap-2 relative">
          <div className="flex flex-row flex-wrap w-full items-center justify-between mt-3">
            <span className="text-lg font-header font-bold ">{title}</span>
            <Button
              icon={<FaPlusCircle />}
              className="flex items-center"
              type="primary"
              onClick={onFullNavigate}
            >
              Ver todos los indicadores
            </Button>
          </div>
          <div className="w-full lg:flex-1 p-2 lg:pr-1">
            <TransactionChart2
              transactions={transactions}
              defaultSelectedKey="monto_facturado"
              max={maxValue}
            />
          </div>

          <div
            className="max-w-32 w-full absolute right-0 h-full flex items-center justify-center overflow-hidden hover:cursor-pointer select-none  transition-all opacity-0 hover:opacity-100"
            onClick={onFullNavigate}
          >
            <div className="absolute w-full h-full bg-gradient-to-r from-transparent to-primary z-0" />
            <div className="absolute w-fit h-full flex flex-col px-12 z-50 items-center justify-center">
              <FaPlusCircle className="text-5xl text-white drop-shadow-md" />
              <span className="font-header text-white text-center drop-shadow-md font-semibold mt-2 leading-none">
                Todos los indicadores
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-full pl-2 pr-4 py-2">
        <div className="flex flex-col w-full h-full bg-white rounded-xl shadow px-2 py-3">
          <PieChartColors />
          {transactions?.map((it, idx) => (
            <PieChartBase
              dataKey={it.key}
              label={it.label!}
              title={it.name}
              key={`pie-${it.key}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
