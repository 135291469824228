import { FC, useContext, useMemo } from "react";
import { SalesContext } from "../context/SalesContext";
import { PIE_COLORS } from "./PieChart";
import { toString } from "lodash";
const PieChartColors: FC = () => {
  const context = useContext(SalesContext);
  const data = context!.data!.data;
  const colorArray = useMemo(
    () =>
      data!
        .filter((it) => toString(it.id) !== "000")
        .map((it, i) => ({
          name: it.name,
          color: PIE_COLORS[i],
        })),
    [data]
  );

  return (
    <div className="flex flex-row flex-wrap flex-shrink gap-x-2 items-center justify-center">
      {colorArray.map((it, i) => (
        <div
          key={`color-${i}`}
          className="text-xs flex flex-row items-center justify-center gap-x-1 !font-body"
        >
          <div
            style={{
              backgroundColor: it.color,
            }}
            className="!w-2 !h-2 rounded-full"
          />

          {it.name}
        </div>
      ))}
    </div>
  );
};

export default PieChartColors;
