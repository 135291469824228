import { isNil, toNumber } from "lodash";

export const parseCurrency = (val: string | number) => {
  return (
    val?.toLocaleString("es-PE", {
      style: "currency",
      currency: "USD",
    }) ?? "0 $"
  );
};

export const parseCurrency2 = (val: string | number) => {
  return !isNil(val)
    ? new Intl.NumberFormat("en-US", {
        style: "decimal",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        currencyDisplay: "narrowSymbol", // This displays the currency as a narrow symbol
      }).format(toNumber(val))
    : "0";
};
