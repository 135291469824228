import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { LoginData } from "../domain/LoginData";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ControlledInput } from "./shared/Input";
import { Button } from "antd";
import { FaKey } from "react-icons/fa";
//@ts-ignore
import LogoWhite from "../resources/Logo-ACME-white.png";
import Cookies from "js-cookie";
import { SalesDataService } from "../data/SalesDataService";
import { useEffect } from "react";
const Validator = Yup.object({
  email: Yup.string()
    .email("Ingresa un correo válido")
    .required("Ingresa un correo electrónico"),
  password: Yup.string().required("Ingresa una contraseña"),
});

const Login = () => {
  const userCookie = Cookies.get("user");
  const user = userCookie ? JSON.parse(userCookie) : null;

  const navigate = useNavigate();
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(Validator),
  });

  useEffect(() => {
    if (!!user?.access_token) {
      navigate("/stats/group/ventas");
    }
  }, []);

  const onSubmit = async (data: LoginData) => {
    try {
      await SalesDataService.login(data);
      navigate("/stats/group/ventas");
    } catch (e: any) {}
  };
  return (
    <div className="h-screen  from-bg  place-content-center bg-acme flex flex-col py-32 px-24">
      <div className="flex flex-col gap-1">
        <img src={LogoWhite} className="max-w-32" alt="logo" />
        <span className="text-white font-header text-xs">
          Innovación y tecnología para la industria
        </span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 py-24">
        <div className="flex flex-col justify-between gap-2">
          <div className="flex flex-col">
            <h2 className="text-secondary text-3xl font-header">Concar ACME</h2>
            <h3 className="font-header text-white">Ingrese a su cuenta</h3>
          </div>
          <div className="" />
        </div>

        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white shadow rounded-lg py-10 px-8 flex flex-col gap-3"
          >
            <ControlledInput
              name="email"
              control={control}
              placeholder="Email"
              type="email"
              label="Email"
            />

            <ControlledInput
              name="password"
              control={control}
              placeholder="Password"
              type="password"
              label="Password"
            />

            <Button
              htmlType="submit"
              size="large"
              type="primary"
              iconPosition="end"
              className="flex flex-row items-center ml-auto"
              icon={<FaKey />}
            >
              Ingresar
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;
