import { LoginData } from "../domain/LoginData";
import {
  GeneralSalesData,
  SalesAdvisorData,
  SalesAdvisorUserType,
  SalesAdvisorViewType,
} from "../domain/SalesAdvisorData";
import { SalesDataRepositoryInterface } from "./SalesDataRepository";

export class SalesDataRepositoryImpl implements SalesDataRepositoryInterface {
  async getSalesDataByTipo(
    tipo: SalesAdvisorUserType
  ): Promise<GeneralSalesData> {
    const response = await fetch(`/back/api/v1/${tipo}`);
    const responseData = await response.json();
    if (!!responseData.data) {
      return responseData;
    } else {
      throw new Error("Ocurrió un error inesperado.");
    }
  }
  async getSalesDataByViewType(
    viewType: SalesAdvisorViewType
  ): Promise<GeneralSalesData> {
    const response = await fetch(`/back/api/v1/${viewType}`);
    const responseData = await response.json();
    if (!!responseData.data) {
      return responseData;
    } else {
      throw new Error("Ocurrió un error inesperado.");
    }
  }
  async login(data: LoginData) {
    const response = await fetch("/back/api/v1/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const jsonData = await response.json();
    if (jsonData.success) {
      localStorage.setItem("user", JSON.stringify(jsonData));
      document.cookie = `user=${JSON.stringify({
        access_token: jsonData.accessToken,
      })}; expires=${jsonData.expiresIn}; path=/`;
    } else {
      if (jsonData.error) {
        throw new Error(jsonData.error);
      } else {
        throw new Error("Ocurió un error inesperado.");
      }
    }
  }

  async getSalesData(): Promise<GeneralSalesData> {
    const response = await fetch(`/back/api/v1/stats`);
    const responseData = await response.json();
    if (!!responseData.data) {
      return responseData;
    } else {
      throw new Error("Ocurrió un error inesperado.");
    }
  }

  async getSalesAdvisorData(advisorId: number): Promise<SalesAdvisorData> {
    const response = await fetch(`/back/api/v1/stats/id/${advisorId}`);
    const responseData = await response.json();
    if (!!responseData.data) {
      return responseData;
    } else {
      throw new Error("Ocurrió un error inesperado.");
    }
  }

  async getAllSalesData(): Promise<GeneralSalesData> {
    const response = await fetch("/back/api/v1/ventas/all");
    const responseData = await response.json();
    if (!!responseData.data) {
      return responseData;
    } else {
      throw new Error("Ocurrió un error inesperado.");
    }
  }
}
