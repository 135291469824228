import { InputHTMLAttributes } from "react";
import { Control, FieldValues, useController, Path } from "react-hook-form";
import classnames from "classnames";
import { isNil } from "lodash";
import { Input } from "antd";
type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  placeholder: string;
  label: string;
  type: InputHTMLAttributes<HTMLInputElement>["type"];
};

export function ControlledInput<T extends FieldValues>({
  control,
  name,
  placeholder,
  type,
  label,
}: Props<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <div className="flex flex-col gap-1 justify-start items-start">
      <span
        className={classnames(
          {
            hidden: isNil(label),
          },
          "text-neutral-600 font-header text-sm"
        )}
      >
        {label}
      </span>
      <Input
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        name={field.name}
        ref={field.ref}
        placeholder={placeholder}
        type={type}
        size="large"
      />
      <span
        className={classnames(
          {
            hidden: isNil(error),
          },
          "text-red-500 text-sm font-body px-3"
        )}
      >
        {error?.message}
      </span>
    </div>
  );
}
