import { isNil, toNumber } from "lodash";
import { LoginData } from "../domain/LoginData";
import { SalesDataRepositoryInterface } from "./SalesDataRepository";
import {
  SalesAdvisorUserType,
  SalesAdvisorViewType,
} from "../domain/SalesAdvisorData";
import { SalesDataRepositoryImpl } from "./SalesDataRepositoryImpl";

export class SalesDataService {
  static Repository: SalesDataRepositoryInterface =
    new SalesDataRepositoryImpl();

  static async login(data: LoginData) {
    return this.Repository.login(data);
  }

  static async getSalesDataByTipo(type: SalesAdvisorUserType) {
    const data = await this.Repository.getSalesDataByTipo(type);
    if (!!data) {
      data.data = data.data!.map((it) => ({
        ...it,
        name: `${it.nombres}${it.apellidos ? ` ${it.apellidos}` : ""}`,
        monto_cotizado: !isNil(it.monto_cotizado)
          ? toNumber(it.monto_cotizado)
          : 0,
        monto_facturado: !isNil(it.monto_facturado)
          ? toNumber(it.monto_facturado)
          : 0,
        monto_oc: !isNil(it.monto_oc) ? toNumber(it.monto_oc) : 0,
        num_cotizado: !isNil(it.num_cotizado) ? toNumber(it.num_cotizado) : 0,
        num_facturado: !isNil(it.num_facturado)
          ? toNumber(it.num_facturado)
          : 0,
        num_oc: !isNil(it.num_oc) ? toNumber(it.num_oc) : 0,
        num_visitas: !isNil(it.num_visitas) ? toNumber(it.num_visitas) : 0,
      }));
    }
    return data;
  }

  static async getSalesDataByViewType(type: SalesAdvisorViewType) {
    const data = await this.Repository.getSalesDataByViewType(type);
    if (!!data) {
      data.data = data.data!.map((it) => ({
        ...it,
        name: `${it.nombres}${it.apellidos ? ` ${it.apellidos}` : ""}`,
        monto_cotizado: !isNil(it.monto_cotizado)
          ? toNumber(it.monto_cotizado)
          : 0,
        monto_facturado: !isNil(it.monto_facturado)
          ? toNumber(it.monto_facturado)
          : 0,
        monto_oc: !isNil(it.monto_oc) ? toNumber(it.monto_oc) : 0,
        num_cotizado: !isNil(it.num_cotizado) ? toNumber(it.num_cotizado) : 0,
        num_facturado: !isNil(it.num_facturado)
          ? toNumber(it.num_facturado)
          : 0,
        num_oc: !isNil(it.num_oc) ? toNumber(it.num_oc) : 0,
        num_visitas: !isNil(it.num_visitas) ? toNumber(it.num_visitas) : 0,
      }));
    }
    return data;
  }

  static async getAllSalesData() {
    const data = await this.Repository.getAllSalesData();
    if (!!data) {
      data.data = data.data!.map((it) => ({
        ...it,
        name: `${it.nombres}${it.apellidos ? ` ${it.apellidos}` : ""}`,
        monto_cotizado: !isNil(it.monto_cotizado)
          ? toNumber(it.monto_cotizado)
          : 0,
        monto_facturado: !isNil(it.monto_facturado)
          ? toNumber(it.monto_facturado)
          : 0,
        monto_oc: !isNil(it.monto_oc) ? toNumber(it.monto_oc) : 0,
        num_cotizado: !isNil(it.num_cotizado) ? toNumber(it.num_cotizado) : 0,
        num_facturado: !isNil(it.num_facturado)
          ? toNumber(it.num_facturado)
          : 0,
        num_oc: !isNil(it.num_oc) ? toNumber(it.num_oc) : 0,
        num_visitas: !isNil(it.num_visitas) ? toNumber(it.num_visitas) : 0,
      }));
    }
    return data;
  }

  static async getSalesAdvisorData(advisorId: number) {
    const data = await this.Repository.getSalesAdvisorData(advisorId);
    if (!!data) {
      data.data = {
        ...data.data,
        name: `${data.data.nombres}${
          data.data?.apellidos ? ` ${data.data.apellidos}` : ""
        }`,
        monto_cotizado: !isNil(data.data.monto_cotizado)
          ? toNumber(data.data.monto_cotizado)
          : 0,
        monto_facturado: !isNil(data.data.monto_facturado)
          ? toNumber(data.data.monto_facturado)
          : 0,
        monto_oc: !isNil(data.data.monto_oc) ? toNumber(data.data.monto_oc) : 0,
        num_cotizado: !isNil(data.data.num_cotizado)
          ? toNumber(data.data.num_cotizado)
          : 0,
        num_facturado: !isNil(data.data.num_facturado)
          ? toNumber(data.data.num_facturado)
          : 0,
        num_oc: !isNil(data.data.num_oc) ? toNumber(data.data.num_oc) : 0,
        num_visitas: !isNil(data.data.num_visitas)
          ? toNumber(data.data.num_visitas)
          : 0,
      };
    }
    return data;
  }
}
