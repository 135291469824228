import { useContext, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Result, Spin, Typography, Upload } from "antd";
import { SalesContext } from "../context/SalesContext";
import { TaskState } from "../domain/TaskState";
const { Dragger } = Upload;

export default function SubirArchivo() {
  const { setData } = useContext(SalesContext);
  const [salesState, setSalesState] = useState<TaskState | null>(null);
  const [quotesState, setQuotesState] = useState<TaskState | null>(null);
  const [ocState, setOcState] = useState<TaskState | null>(null);

  const salesProps = {
    name: "file",
    multiple: false,
    action: "/back/api/v1/sales",
    onChange(info: any) {
      const { status } = info.file;
      if (status !== "uploading") {
        setSalesState({
          error: null,
          loading: true,
        });
      }
      if (status === "done") {
        const object = JSON.parse(info.file.xhr.response);
        setData(object);
        message.success(`${info.file.name} subido correctamente.`);
        setSalesState({
          error: null,
          loading: false,
        });
      } else if (status === "error") {
        message.error(
          `Ocurrió un error al subir el archivo ${info.file.name}.`
        );
        setSalesState({
          error: `Ocurrió un error al subir el archivo ${info.file.name}.`,
          loading: false,
        });
      }
    },
    onDrop(e: any) {},
  };

  const quotesProps = {
    name: "file",
    multiple: false,
    action: "/back/api/v1/quotes",
    onChange(info: any) {
      const { status } = info.file;
      if (status !== "uploading") {
        setQuotesState({
          error: null,
          loading: true,
        });
      }
      if (status === "done") {
        const object = JSON.parse(info.file.xhr.response);
        setData(object);
        message.success(`${info.file.name} subido correctamente.`);
        setQuotesState({
          error: null,
          loading: false,
        });
      } else if (status === "error") {
        message.error(
          `Ocurrió un error al subir el archivo ${info.file.name}.`
        );
        setQuotesState({
          error: `Ocurrió un error al subir el archivo ${info.file.name}.`,
          loading: false,
        });
      }
    },
    onDrop(e: any) {},
  };

  const ocProps = {
    name: "file",
    multiple: false,
    action: "/back/api/v1/oc",
    onChange(info: any) {
      const { status } = info.file;
      if (status !== "uploading") {
        setOcState({
          error: null,
          loading: true,
        });
      }
      if (status === "done") {
        const object = JSON.parse(info.file.xhr.response);
        setData(object);
        message.success(`${info.file.name} subido correctamente.`);
        setOcState({
          error: null,
          loading: false,
        });
      } else if (status === "error") {
        message.error(
          `Ocurrió un error al subir el archivo ${info.file.name}.`
        );
        setOcState({
          error: `Ocurrió un error al subir el archivo ${info.file.name}.`,
          loading: false,
        });
      }
    },
    onDrop(e: any) {},
  };

  return (
    <div className="w-full lg:h-full grid grid-cols-1 lg:grid-rows-2 gap-2">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="flex flex-col gap-2 p-3">
          <Typography.Text underline>Reporte de Ventas</Typography.Text>
          <div className="h-full w-full flex flex-col justify-center items-center m-auto relative">
            {!!salesState && !salesState?.loading && !salesState?.error ? (
              <div className="w-full h-full absolute z-50">
                <Result
                  status={"success"}
                  title={"Subido correctamente"}
                  subTitle={
                    <Typography.Text type="secondary">
                      Se ha registrado el archivo correctamente
                    </Typography.Text>
                  }
                />
              </div>
            ) : !!salesState?.loading ? (
              <div className="w-full h-full flex items-center justify-center">
                <Spin size="large" />
              </div>
            ) : (
              <Dragger
                {...salesProps}
                className="h-full w-full overflow-hidden bg-white shadow rounded-lg"
              >
                <p className="fill-primary text-3xl md:text-4xl lg:text-6xl">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text font-header">
                  Haga click o arrastre el archivo de{" "}
                  <span className="underline font-header font-semibold bg-secondary text-white">
                    reporte de ventas
                  </span>
                </p>
                <p className="ant-upload-hint font-body">
                  Se permite actualizar sólo un archivo excel a la vez.
                </p>
              </Dragger>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 p-3">
          <Typography.Text underline>Cotizaciones</Typography.Text>
          <div className="h-full w-full flex flex-col justify-center items-center m-auto relative">
            {!!quotesState && !quotesState?.loading && !quotesState?.error ? (
              <div className="w-full h-full absolute z-50">
                <Result
                  status={"success"}
                  title={"Subido correctamente"}
                  subTitle={
                    <Typography.Text type="secondary">
                      Se ha registrado el archivo correctamente
                    </Typography.Text>
                  }
                />
              </div>
            ) : !!quotesState?.loading ? (
              <div className="w-full h-full flex items-center justify-center">
                <Spin size="large" />
              </div>
            ) : (
              <Dragger
                {...quotesProps}
                className="h-full w-full overflow-hidden bg-white shadow rounded-lg"
              >
                <p className="fill-primary text-3xl md:text-4xl lg:text-6xl">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text font-header">
                  Haga click o arrastre el archivo de{" "}
                  <span className="underline font-header font-semibold bg-secondary text-white">
                    cotizaciones
                  </span>
                </p>
                <p className="ant-upload-hint font-body">
                  Se permite actualizar sólo un archivo excel a la vez.
                </p>
              </Dragger>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 px-3 pb-3">
        <Typography.Text underline>Órdenes de Compra</Typography.Text>
        <div className="h-full w-full flex flex-col justify-center items-center m-auto relative">
          {!!ocState && !ocState?.loading && !ocState?.error ? (
            <div className="w-full h-full absolute z-50">
              <Result
                status={"success"}
                title={"Subido correctamente"}
                subTitle={
                  <Typography.Text type="secondary">
                    Se ha registrado el archivo correctamente
                  </Typography.Text>
                }
              />
            </div>
          ) : !!ocState?.loading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Spin size="large" />
            </div>
          ) : (
            <Dragger
              {...ocProps}
              className="h-full w-full overflow-hidden bg-white shadow rounded-lg"
            >
              <p className="fill-primary text-3xl md:text-4xl lg:text-6xl">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text font-header">
                Haga click o arrastre el archivo de{" "}
                <span className="underline font-header font-semibold bg-secondary text-white">
                  reporte de órdenes de compra
                </span>
              </p>
              <p className="ant-upload-hint font-body">
                Se permite actualizar sólo un archivo excel a la vez.
              </p>
            </Dragger>
          )}
        </div>
      </div>
    </div>
  );
}
