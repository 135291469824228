import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Cookies from "js-cookie";
import { isNil } from "lodash";

export default function Layout() {
  const userCookie = Cookies.get("user");
  const user = userCookie ? JSON.parse(userCookie) : null;

  const navigate = useNavigate();

  useEffect(() => {
    if (isNil(user?.access_token)) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="h-screen w-screen flex flex-col overflow-hidden bg-neutral-200">
      <Header />
      <div className="w-full flex-grow overflow-hidden">{<Outlet />}</div>
    </div>
  );
}
