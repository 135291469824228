import { TransactionIndicator } from "./TransactionChart2";
import { FC, useCallback } from "react";
import { Breadcrumb, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { TransactionChartGeneral } from "./TransactionChartGeneral";

type Props = {};

export const DashboardGeneralContent: FC<Props> = () => {
  const transactions: Array<TransactionIndicator> = [
    {
      key: "num_visitas",
      color: "#2143a5",
      name: "Num. Visitas",
      label: "Visitas",
    },

    {
      key: "monto_facturado",
      color: "#f8aa19",
      name: "Monto Facturado",
      label: "Facturado",
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const onGoBack = useCallback(() => {
    navigate(location.pathname.replaceAll("/all", ""));
  }, [location, navigate]);

  const title = "Valor de venta general";

  return (
    <div className="px-2 py-2 w-full h-full flex flex-col">
      <div className="px-2 flex flex-row flex-wrap items-center gap-2">
        <Button
          icon={<FaChevronLeft />}
          type="text"
          onClick={onGoBack}
          className="!text-primary"
        />
        <Breadcrumb
          items={[
            {
              title: "General",
              onClick: onGoBack,
              className: "text-primary cursor-pointer",
            },
            {
              title: "Todos los indicadores",
            },
          ]}
        />
      </div>
      <div className="lg:h-full flex flex-col px-4 w-full bg-white rounded-xl lg:overflow-hidden shadow gap-2 relative">
        <div className="flex flex-row flex-wrap w-full items-center justify-between mt-3">
          <span className="text-lg font-header font-bold ">{title}</span>
        </div>
        <div className="w-full lg:flex-1 p-2 lg:pr-1">
          <TransactionChartGeneral transactions={transactions} />
        </div>
      </div>
    </div>
  );
};
