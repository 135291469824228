import { useContext } from "react";
import { TransactionChartSingle } from "./TransactionChartSingle";
import { SalesContext } from "../context/SalesContext";
import { TransactionIndicator } from "./TransactionChart2";

export const AdvisorDashboardContent = () => {
  const { advisorData } = useContext(SalesContext);

  const transactions: Array<TransactionIndicator> = (() => {
    switch (advisorData!.tipo) {
      case "distribuidor":
        return [
          {
            key: "monto_oc",
            color: "#ea6839",
            name: "Monto Orden de Compra",
            label: "O. Compra",
          },
          {
            key: "monto_facturado",
            color: "#f8aa19",
            name: "Monto Facturado",
            label: "Facturado",
          },
        ];
      case "ingeniero":
      case "asesor":
      default:
        return [
          {
            key: "num_visitas",
            color: "#2143a5",
            name: "Num. Visitas",
            label: "Visitas",
          },
          {
            key: "monto_cotizado",
            color: "rgba(26, 42, 83, 1)",
            name: "Monto Cotizado",
            label: "Cotizado",
          },
          {
            key: "monto_oc",
            color: "#ea6839",
            name: "Monto Orden de Compra",
            label: "O. Compra",
          },
          {
            key: "monto_facturado",
            color: "#f8aa19",
            name: "Monto Facturado",
            label: "Facturado",
          },
        ];
    }
  })();
  return (
    <div className="w-screen flex flex-col lg:h-full lg:overflow-hidden ">
      <div className="w-full lg:flex-1 flex flex-row lg:overflow-hidden p-3 gap-3">
        <div className="w-full h-full">
          <TransactionChartSingle transactions={transactions} />
        </div>
      </div>
    </div>
  );
};
