import { isNil } from "lodash";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SalesDataService } from "../data/SalesDataService";
import { SalesContext } from "../context/SalesContext";
import { Button, Empty, Result, Spin, Typography } from "antd";
import { AdvisorDashboardContent } from "./AdvisorDashboardContent";
import { FaChevronLeft, FaRedo } from "react-icons/fa";

export const AdvisorDashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setAdvisorData, advisorDataState, setAdvisorDataState, advisorData } =
    useContext(SalesContext);

  function goBack() {
    navigate(-1);
  }

  function fetchData() {
    if (!isNil(id)) {
      setAdvisorDataState({ loading: true, error: null });
      SalesDataService.getSalesAdvisorData(id as unknown as number)
        .then((it) => {
          setAdvisorData(it!.data);
          setAdvisorDataState({ loading: false, error: null });
        })
        .catch((err) => {
          console.log(err);
          setAdvisorDataState({ loading: false, error: err.message });
        });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full lg:h-full flex flex-col">
      <div className="px-16 py-2 flex flex-row gap-4 items-start justify-start sticky top-0">
        <Button type="text" icon={<FaChevronLeft />} onClick={goBack} />
        <div className="flex flex-col">
          <Typography.Title level={5} className="!p-0 !m-0 font-header">
            Estadísticas del Vendedor
          </Typography.Title>
          {advisorData?.name && (
            <Typography.Text>{advisorData?.name}</Typography.Text>
          )}
        </div>
      </div>

      {advisorDataState.loading ? (
        <div className="w-full h-full flex flex-col justify-center items-center gap-4">
          <Spin
            size="large"
            fullscreen
            tip={
              <Typography.Text type="secondary">
                Obteniendo estadísticas del vendedor
              </Typography.Text>
            }
          />
        </div>
      ) : advisorDataState.error ? (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <Result
            status={"error"}
            title={"Ocurrió un error"}
            subTitle={
              "Ocurrió un error al intentar obtener las estadísticas del vendedor"
            }
            extra={
              <div className="flex flex-row justify-center">
                <Button
                  type="primary"
                  className="justify-center items-center flex flex-row gap-2"
                  onClick={fetchData}
                >
                  <FaRedo className="my-auto" />
                  Reintentar
                </Button>
              </div>
            }
            className="overflow-y-auto"
          >
            <Typography.Text code>{advisorDataState.error}</Typography.Text>
          </Result>
        </div>
      ) : isNil(advisorData) ? (
        <div className="w-full lg:h-full flex items-center justify-center">
          <Empty description={"No existen datos para este vendedor."} />
        </div>
      ) : (
        <div className="w-full lg:h-full ">
          <AdvisorDashboardContent />
        </div>
      )}
    </div>
  );
};
