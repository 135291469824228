import { FC, useContext, useEffect, useMemo, useState } from "react";
import { SalesContext } from "../context/SalesContext";
import { Pie, PieConfig } from "@ant-design/plots";
import { toNumber, toString } from "lodash";
import { SingleSalesAdvisorData } from "../domain/SalesAdvisorData";

export const PIE_COLORS = [
  "#0060AE",
  "#D92231",
  "#00938B",
  "#9D3F97",
  "#A9B533",
  "#FFC107",
  "#7F2A47",
  "#F37321",
  "#EC0089",
  "#009BDA",
  "#EF3829",
  "#B9971A",
];

type Props = {
  dataKey: keyof SingleSalesAdvisorData;
  title: string;
  label: string;
};

const PieChartBase: FC<Props> = ({ dataKey, title, label }) => {
  const context = useContext(SalesContext);
  const data = context!.data!.data;
  const [amountConfig, setAmountConfig] = useState<PieConfig>();

  const amountArray = useMemo(() => {
    const total = data!.reduce((prev, it) => toNumber(it[dataKey]) + prev, 0);

    return data!
      .filter((it) => toString(it.id) !== "000")
      .map((it) => ({
        name: it.name,
        value: it[dataKey],
        total,
      }));
  }, [data]);

  useEffect(() => {
    setAmountConfig({
      data: amountArray,
      angleField: "value",
      colorField: "name",
      radius: 0.8,
      title: {
        title: title,
        style: {
          titleFontFamily: "Poppins",
        },
      },
      label: {
        text: (item: any) => {
          return `${parseInt(`${(item.value / item.total) * 100}`)}%`;
        },
        style: {
          fontSize: 10,
          fontFamily: "Poppins",
          // textBaseline: "bottom",
          // textAlign: "start",
        },
        opacity: 1,
        background: false,
        position: "outside",
        connector: true,
        lineWidth: 0,
      },
      scale: {
        color: {
          palette: PIE_COLORS,
        },
      },
      legend: false,
      tooltip: {
        title: "name",

        shared: false,

        items: [
          {
            channel: "y",
            field: "value",
            name: label,
            valueFormatter: (val: number) => {
              return val.toLocaleString("es-PE", {
                style: "currency",
                currency: "USD",
              });
            },
          },
        ],
        style: {
          fontSize: 10,
          fontFamily: "Poppins",
        },
      },

      innerRadius: 0.4,
      annotations: [
        {
          type: "text",
          tooltip: false,
          style: {
            text: "$",
            x: "50%",
            y: "45%",
            textAlign: "center",
            fontSize: 18,
            fontWeight: 400,
            fontFamily: "Poppins",
          },
        },
        {
          type: "text",
          tooltip: false,
          style: {
            text: label,
            x: "50%",
            y: "55%",
            textAlign: "center",
            fontSize: 10,
            fontWeight: 600,
            fontFamily: "Poppins",
          },
        },
      ],
    });
  }, [amountArray]);

  return <Pie {...amountConfig} />;
};

export default PieChartBase;
