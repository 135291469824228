import { FC, Fragment, useContext, useMemo, useState } from "react";
import { SalesContext } from "../context/SalesContext";
import { isEmpty, isNil } from "lodash";
import { parseCurrency, parseCurrency2 } from "../lib/utils/currency";
import {
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  LegendProps,
} from "recharts";
import { formatChartNames } from "../lib/utils/name";
import { SingleSalesAdvisorData } from "../domain/SalesAdvisorData";
import { TransactionIndicator } from "./TransactionChart2";

type Props = {
  transactions: Array<TransactionIndicator>;
};

type BarSelectionProps = {
  hover: keyof SingleSalesAdvisorData | null;
} & {
  [key in keyof Partial<SingleSalesAdvisorData>]: boolean | null;
};

export const TransactionChartSingle: FC<Props> = ({ transactions }) => {
  const context = useContext(SalesContext);
  const data = context!.advisorData!;

  const defaultBarProps = (() => {
    const it2 = transactions.reduce(
      (prev, current) => ({
        ...prev,
        [current.key]: false,
      }),
      {}
    );
    let result = {
      hover: null,
    };
    result = {
      ...result,
      ...it2,
    };
    return result;
  })();

  const [barProps, setBarProps] = useState<BarSelectionProps>(defaultBarProps);

  const handleLegendMouseEnter: LegendProps["onMouseOver"] = (e) => {
    const dataKey = e.dataKey as keyof SingleSalesAdvisorData | undefined;
    if (!isNil(dataKey)) {
      setBarProps({ ...barProps, hover: dataKey });
    }
  };

  const handleLegendMouseLeave: LegendProps["onMouseOut"] = (_) => {
    setBarProps({ ...barProps, hover: null });
  };

  const selectBar: LegendProps["onClick"] = (e) => {
    const dataKey = e.dataKey as keyof SingleSalesAdvisorData | undefined;
    setBarProps({
      ...barProps,
      [dataKey!]: !barProps[dataKey!],
      hover: null,
    });
  };

  const renderVisitas = (color: string) => (tickProps: any) => {
    const { x, y } = tickProps;
    const value = data["num_visitas"];
    if (!isNil(value)) {
      return (
        <text
          x={x + 10}
          y={y + 4}
          fill={color}
          fontFamily="Poppins"
          fontSize={30}
          fontWeight={700}
        >{`${value}`}</text>
      );
    }
    return <Fragment />;
  };

  const maxYAxis: number = (() => {
    switch (data.tipo) {
      case "asesor":
        return 35000;
      case "ingeniero":
      case "servicios":
        return 50000;
      default:
        return 50000;
    }
  })();

  const toRenderBars = useMemo(() => {
    return transactions.filter((it) => it.key.startsWith("num"));
  }, [barProps, transactions]);

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <BarChart data={[data]}>
        <CartesianGrid strokeDasharray="3 3 0 0" vertical={false} />
        <XAxis
          dataKey="name"
          tickFormatter={formatChartNames}
          className={
            "text-xs md:text-sm lg:text-md font-header font-semibold text-black"
          }
        />
        <YAxis
          domain={[0, maxYAxis]}
          tickCount={5}
          className={
            "text-xs md:text-sm lg:text-sm font-header font-semibold text-black"
          }
          width={90}
          tickFormatter={parseCurrency2}
        />
        <Tooltip
          contentStyle={{ fontWeight: 500 }}
          itemStyle={{ fontWeight: 500 }}
          cursor={{
            strokeWidth: 2,
            fill: "rgba(50,50,50,0.1)",
          }}
          formatter={parseCurrency}
          labelClassName="font-header outline-none border-none"
          wrapperClassName="font-header outline-none border-none"
          content={({ payload, label }) => {
            if (isEmpty(payload)) {
              return null;
            }
            const data = payload![0].payload;
            return (
              <div className="bg-white border border-gray-300 shadow-lg rounded-md p-2 gap-2">
                <div className="font-header font-semibold text-black text-sm">
                  {label}
                </div>
                {transactions.map((label, index) => (
                  <div
                    key={`tooltip-${index}`}
                    className="flex flex-col items-start justify-between"
                  >
                    <div
                      className="font-header font-semibold text-black text-xs flex flex-row gap-1 items-center underline"
                      style={{
                        textDecorationColor: label.color,
                      }}
                    >
                      <div
                        style={{ backgroundColor: label.color }}
                        className="w-2 aspect-square"
                      />
                      {label.name}
                    </div>
                    <div className="font-header font-normal text-black text-xs">
                      {label.key.includes("num")
                        ? data[label.key]
                        : parseCurrency(data[label.key])}
                    </div>
                  </div>
                ))}
              </div>
            );
          }}
        />
        <Legend
          onClick={selectBar}
          onMouseOver={handleLegendMouseEnter}
          onMouseOut={handleLegendMouseLeave}
        />

        {transactions
          .filter((it) => it.key !== "num_visitas")
          .map((label, index) => (
            <Bar
              radius={[5, 5, 0, 0]}
              dataKey={label.key}
              fill={label.color}
              name={label.name}
              key={`bar-${index}`}
              hide={barProps[label.key] === true}
              fillOpacity={Number(
                barProps.hover === label.key || !barProps.hover ? 1 : 0.6
              )}
            >
              <LabelList
                dataKey={label.key}
                position="top"
                angle={0}
                className={
                  "invisible md:visible text-xs md:text-sm font-semibold font-header"
                }
                formatter={parseCurrency2}
              />
            </Bar>
          ))}

        {!!toRenderBars &&
          toRenderBars.map((it) => (
            <XAxis
              key={`xaxis-${it.key}`}
              dataKey="name"
              axisLine={false}
              tickLine={false}
              interval={0}
              tick={renderVisitas(it.color)}
              scale="band"
              xAxisId={it.key}
              label={(props: any) => {
                const {
                  viewBox: { y },
                } = props;
                return (
                  <text
                    x={14}
                    y={y + 10}
                    fontFamily="Poppins"
                    fillOpacity={0.6}
                    fontSize={20}
                    fontWeight={500}
                  >
                    {it.label}
                  </text>
                );
              }}
            />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
