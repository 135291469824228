import { useContext, useEffect } from "react";
import { SalesContext } from "../context/SalesContext";
import { Empty, Spin, Typography } from "antd";
import { SalesDataService } from "../data/SalesDataService";
import { isEmpty } from "lodash";
import { DashboardGeneralContent } from "./DashboardGeneralContent";

export default function DashboardGeneral() {
  const { generalData, generalDataState, setGeneralDataState, setGeneralData } =
    useContext(SalesContext);

  useEffect(() => {
    setGeneralDataState({ loading: true, error: null });
    SalesDataService.getAllSalesData()
      .then((res) => {
        setGeneralData(res);
        setGeneralDataState({ loading: false, error: null });
      })
      .catch((err) => {
        setGeneralDataState({ loading: false, error: err.message });
      });
  }, []);

  return (
    <div className="w-full h-full overflow-x-hidden overflow-y-auto lg:overflow-y-hidden">
      {generalDataState?.loading ? (
        <div className="w-full h-full flex flex-col justify-center items-center gap-4">
          <Spin
            size="large"
            fullscreen
            tip={
              <Typography.Text type="secondary">
                Obteniendo estadísticas generales..
              </Typography.Text>
            }
          />
        </div>
      ) : !isEmpty(generalData?.data) ? (
        <DashboardGeneralContent />
      ) : (
        <Empty />
      )}
    </div>
  );
}
