import { createContext, FC, PropsWithChildren, useState } from "react";
import {
  GeneralSalesData,
  SingleSalesAdvisorData,
} from "../domain/SalesAdvisorData";
import { TaskState } from "../domain/TaskState";

type ContextValue = {
  data?: GeneralSalesData | null;
  setData: (data: GeneralSalesData) => void;
  advisorData?: SingleSalesAdvisorData | null;
  setAdvisorData: (data: SingleSalesAdvisorData) => void;
  generalData?: GeneralSalesData | null;
  setGeneralData: (data: GeneralSalesData) => void;
  setDataState: (newState: TaskState) => void;
  setAdvisorDataState: (newState: TaskState) => void;
  setGeneralDataState: (newState: TaskState) => void;
  generalDataState: TaskState;
  dataState: TaskState;
  advisorDataState: TaskState;
};

export const SalesContext = createContext<ContextValue>({
  data: null!,
  setData: null!,
  advisorData: null!,
  setAdvisorData: null!,
  dataState: null!,
  advisorDataState: null!,
  setDataState: null!,
  setAdvisorDataState: null!,
  generalData: null!,
  setGeneralData: null!,
  generalDataState: null!,
  setGeneralDataState: null!,
});

export const SalesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [data, setData] = useState<GeneralSalesData>({
    data: [],
    timestamp: 0,
  });
  const [generalData, setGeneralData] = useState<GeneralSalesData>({
    data: [],
    timestamp: 0,
  });
  const [dataState, setDataState] = useState<TaskState>({
    loading: false,
    error: null,
  });
  const [generalDataState, setGeneralDataState] = useState<TaskState>({
    loading: false,
    error: null,
  });
  const [advisorDataState, setAdvisorDataState] = useState<TaskState>({
    loading: false,
    error: null,
  });
  const [advisorData, setAdvisorData] = useState<SingleSalesAdvisorData>();
  return (
    <SalesContext.Provider
      value={{
        data,
        setData,
        advisorData,
        setAdvisorData,
        advisorDataState,
        setAdvisorDataState,
        dataState,
        setDataState,
        generalDataState,
        setGeneralDataState,
        generalData,
        setGeneralData,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};
