export const SALES_ADVISOR_USER_TYPES = [
  "asesor",
  "distribuidor",
  "ingeniero",
  "servicios",
] as const;

export const SALES_ADVISOR_VIEW_TYPES = [
  "ventas",
  "distribuidores",
  "proyectos",
] as const;
